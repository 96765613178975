import React from 'react';
import PropTypes from 'prop-types';
import './_icon-button.scss';
import MaterialIcon from '../MaterialIcon/MaterialIcon';
import classNames from 'classnames';

function IconButton({ icon, clickHandler, text, iconClassName, buttonClassName, role, tabIndex }) {
    return <div className="icon-button">
        <span className="icon-button__icon">
            <MaterialIcon icon={icon} className={iconClassName} ariaHidden/>
        </span>
        <button
            className={classNames(['link icon-button__text', buttonClassName])}
            role={role}
            tabIndex={tabIndex}
            onClick={clickHandler}
        >
            {text}
        </button>
    </div>;
}

IconButton.propTypes = {
    icon: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    clickHandler: PropTypes.func.isRequired,
    iconClassName: PropTypes.string,
    buttonClassName: PropTypes.string,
    role: PropTypes.string,
    tabIndex: PropTypes.string
};

export default IconButton;